import {
  GET_ISSUERS,
  GET_SECONDARY_PARTY_OPTIONS,
  GET_SUSTAINABLE_DEVELOPMENT_GOALS,
  GET_UOP_CATEGORIES,
  GET_INSTRUMENTS
} from '../../types/appConstants'

// **  Initial State
const initialState = {
  issuers: null,
  secondPartyOpinions: null,
  sustainableDevelopmentGoals: null,
  uopCategories: null,
  instruments: null
}

const tbadataReducer = (state = initialState, action) => {  
  switch (action.type) {
    case GET_ISSUERS:
      return { ...state, issuers: action.payload }
    case GET_SECONDARY_PARTY_OPTIONS:
      return { ...state, secondPartyOpinions: action.payload }
    case GET_SUSTAINABLE_DEVELOPMENT_GOALS:
      return { ...state, sustainableDevelopmentGoals: action.payload }
    case GET_UOP_CATEGORIES:
      return { ...state, uopCategories: action.payload }
    case GET_INSTRUMENTS:
      return { ...state, instruments: action.payload }
    default:
      return state
  }
}

export default tbadataReducer
